<template>
  <div id="app">
    <NavBar />
    <router-view />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  components: {
    NavBar: NavBar
  },
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
