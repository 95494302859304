export const animals = [
  'aardvark',
  'aardwolf',
  'abalone',
  'abyssiniancat',
  'abyssiniangroundhornbill',
  'acaciarat',
  'achillestang',
  'acornbarnacle',
  'acornweevil',
  'acornwoodpecker',
  'acouchi',
  'adamsstaghornedbeetle',
  'addax',
  'adder',
  'adeliepenguin',
  'admiralbutterfly',
  'adouri',
  'aegeancat',
  'affenpinscher',
  'afghanhound',
  'africanaugurbuzzard',
  'africanbushviper',
  'africancivet',
  'africanclawedfrog',
  'africanelephant',
  'africanfisheagle',
  'africangoldencat',
  'africangroundhornbill',
  'africanharrierhawk',
  'africanhornbill',
  'africanjacana',
  'africanmolesnake',
  'africanparadiseflycatcher',
  'africanpiedkingfisher',
  'africanporcupine',
  'africanrockpython',
  'africanwildcat',
  'africanwilddog',
  'agama',
  'agouti',
  'aidi',
  'airedale',
  'airedaleterrier',
  'akitainu',
  'alabamamapturtle',
  'alaskajingle',
  'alaskanhusky',
  'alaskankleekai',
  'alaskanmalamute',
  'albacoretuna',
  'albatross',
  'albertosaurus',
  'albino',
  'aldabratortoise',
  'allensbigearedbat',
  'alleycat',
  'alligator',
  'alligatorgar',
  'alligatorsnappingturtle',
  'allosaurus',
  'alpaca',
  'alpinegoat',
  'alpineroadguidetigerbeetle',
  'altiplanochinchillamouse',
  'amazondolphin',
  'amazonparrot',
  'amazontreeboa',
  'amberpenshell',
  'ambushbug',
  'americanalligator',
  'americanavocet',
  'americanbadger',
  'americanbittern',
  'americanblackvulture',
  'americanbobtail',
  'americanbulldog',
  'americancicada',
  'americancrayfish',
  'americancreamdraft',
  'americancrocodile',
  'americancrow',
  'americancurl',
  'americangoldfinch',
  'americanindianhorse',
  'americankestrel',
  'americanlobster',
  'americanmarten',
  'americanpainthorse',
  'americanquarterhorse',
  'americanratsnake',
  'americanredsquirrel',
  'americanriverotter',
  'americanrobin',
  'americansaddlebred',
  'americanshorthair',
  'americantoad',
  'americanwarmblood',
  'americanwigeon',
  'americanwirehair',
  'amethystgemclam',
  'amethystinepython',
  'amethystsunbird',
  'ammonite',
  'amoeba',
  'amphibian',
  'amphiuma',
  'amurminnow',
  'amurratsnake',
  'amurstarfish',
  'anaconda',
  'anchovy',
  'andalusianhorse',
  'andeancat',
  'andeancockoftherock',
  'andeancondor',
  'anemone',
  'anemonecrab',
  'anemoneshrimp',
  'angelfish',
  'angelwingmussel',
  'anglerfish',
  'angora',
  'angwantibo',
  'anhinga',
  'ankole',
  'ankolewatusi',
  'annashummingbird',
  'annelid',
  'annelida',
  'anole',
  'anophelesmosquito',
  'ant',
  'antarcticfurseal',
  'antarcticgiantpetrel',
  'antbear',
  'anteater',
  'antelope',
  'antelopegroundsquirrel',
  'antipodesgreenparakeet',
  'antlion',
  'anura',
  'aoudad',
  'apatosaur',
  'ape',
  'aphid',
  'apisdorsatalaboriosa',
  'aplomadofalcon',
  'appaloosa',
  'aquaticleech',
  'arabianhorse',
  'arabianoryx',
  'arabianwildcat',
  'aracari',
  'arachnid',
  'arawana',
  'archaeocete',
  'archaeopteryx',
  'archerfish',
  'arcticduck',
  'arcticfox',
  'arctichare',
  'arcticseal',
  'arcticwolf',
  'argali',
  'argentinehornedfrog',
  'argentineruddyduck',
  'argusfish',
  'arieltoucan',
  'arizonaalligatorlizard',
  'arkshell',
  'armadillo',
  'armedcrab',
  'armednylonshrimp',
  'armyant',
  'armyworm',
  'arrowana',
  'arrowcrab',
  'arrowworm',
  'arthropods',
  'aruanas',
  'asianconstablebutterfly',
  'asiandamselfly',
  'asianelephant',
  'asianlion',
  'asianpiedstarling',
  'asianporcupine',
  'asiansmallclawedotter',
  'asiantrumpetfish',
  'asianwaterbuffalo',
  'asiaticgreaterfreshwaterclam',
  'asiaticlesserfreshwaterclam',
  'asiaticmouflon',
  'asiaticwildass',
  'asp',
  'ass',
  'assassinbug',
  'astarte',
  'astrangiacoral',
  'atlanticblackgoby',
  'atlanticbluetang',
  'atlanticridleyturtle',
  'atlanticsharpnosepuffer',
  'atlanticspadefish',
  'atlasmoth',
  'attwatersprairiechicken',
  'auk',
  'auklet',
  'aurochs',
  'australiancattledog',
  'australiancurlew',
  'australianfreshwatercrocodile',
  'australianfurseal',
  'australiankelpie',
  'australiankestrel',
  'australianshelduck',
  'australiansilkyterrier',
  'austrianpinscher',
  'avians',
  'avocet',
  'axisdeer',
  'axolotl',
  'ayeaye',
  'aztecant',
  'azurevase',
  'azurevasesponge',
  'azurewingedmagpie',
  'babirusa',
  'baboon',
  'backswimmer',
  'bactrian',
  'badger',
  'bagworm',
  'baiji',
  'baldeagle',
  'baleenwhale',
  'balloonfish',
  'ballpython',
  'bandicoot',
  'bangeltiger',
  'bantamrooster',
  'banteng',
  'barasinga',
  'barasingha',
  'barb',
  'barbet',
  'barebirdbat',
  'barnacle',
  'barnowl',
  'barnswallow',
  'barracuda',
  'basenji',
  'basil',
  'basilisk',
  'bass',
  'bassethound',
  'bat',
  'bats',
  'beagle',
  'bear',
  'beardedcollie',
  'beardeddragon',
  'beauceron',
  'beaver',
  'bedbug',
  'bedlingtonterrier',
  'bee',
  'beetle',
  'bellfrog',
  'bellsnake',
  'belugawhale',
  'bengaltiger',
  'bergerpicard',
  'bernesemountaindog',
  'betafish',
  'bettong',
  'bichonfrise',
  'bighorn',
  'bighornedsheep',
  'bighornsheep',
  'bigmouthbass',
  'bilby',
  'billygoat',
  'binturong',
  'bird',
  'birdofparadise',
  'bison',
  'bittern',
  'blackandtancoonhound',
  'blackbear',
  'blackbird',
  'blackbuck',
  'blackcrappie',
  'blackfish',
  'blackfly',
  'blackfootedferret',
  'blacklab',
  'blacklemur',
  'blackmamba',
  'blacknorwegianelkhound',
  'blackpanther',
  'blackrhino',
  'blackrussianterrier',
  'blackwidowspider',
  'blesbok',
  'blobfish',
  'blowfish',
  'blueandgoldmackaw',
  'bluebird',
  'bluebottle',
  'bluebottlejellyfish',
  'bluebreastedkookaburra',
  'bluefintuna',
  'bluefish',
  'bluegill',
  'bluejay',
  'bluemorphobutterfly',
  'blueshark',
  'bluet',
  'bluetickcoonhound',
  'bluetonguelizard',
  'bluewhale',
  'boa',
  'boaconstrictor',
  'boar',
  'bobcat',
  'bobolink',
  'bobwhite',
  'boilweevil',
  'bongo',
  'bonobo',
  'booby',
  'bordercollie',
  'borderterrier',
  'borer',
  'borzoi',
  'boto',
  'boubou',
  'boutu',
  'bovine',
  'brahmanbull',
  'brahmancow',
  'brant',
  'bream',
  'brocketdeer',
  'bronco',
  'brontosaurus',
  'brownbear',
  'brownbutterfly',
  'bubblefish',
  'buck',
  'buckeyebutterfly',
  'budgie',
  'bufeo',
  'buffalo',
  'bufflehead',
  'bug',
  'bull',
  'bullfrog',
  'bullmastiff',
  'bumblebee',
  'bunny',
  'bunting',
  'burro',
  'bushbaby',
  'bushsqueaker',
  'bustard',
  'butterfly',
  'buzzard',
  'caecilian',
  'caiman',
  'caimanlizard',
  'calf',
  'camel',
  'canadagoose',
  'canary',
  'canine',
  'canvasback',
  'capeghostfrog',
  'capybara',
  'caracal',
  'cardinal',
  'caribou',
  'carp',
  'carpenterant',
  'cassowary',
  'cat',
  'catbird',
  'caterpillar',
  'catfish',
  'cats',
  'cattle',
  'caudata',
  'cavy',
  'centipede',
  'cero',
  'chafer',
  'chameleon',
  'chamois',
  'chanticleer',
  'cheetah',
  'chevrotain',
  'chick',
  'chickadee',
  'chicken',
  'chihuahua',
  'chimneyswift',
  'chimpanzee',
  'chinchilla',
  'chinesecrocodilelizard',
  'chipmunk',
  'chital',
  'chrysalis',
  'chrysomelid',
  'chuckwalla',
  'chupacabra',
  'cicada',
  'cirriped',
  'civet',
  'clam',
  'cleanerwrasse',
  'clingfish',
  'clownanemonefish',
  'clumber',
  'coati',
  'cob',
  'cobra',
  'cock',
  'cockatiel',
  'cockatoo',
  'cockerspaniel',
  'cockroach',
  'cod',
  'coelacanth',
  'collardlizard',
  'collie',
  'colt',
  'comet',
  'commabutterfly',
  'commongonolek',
  'conch',
  'condor',
  'coney',
  'conure',
  'cony',
  'coot',
  'cooter',
  'copepod',
  'copperbutterfly',
  'copperhead',
  'coqui',
  'coral',
  'cormorant',
  'cornsnake',
  'corydorascatfish',
  'cottonmouth',
  'cottontail',
  'cougar',
  'cow',
  'cowbird',
  'cowrie',
  'coyote',
  'coypu',
  'crab',
  'crane',
  'cranefly',
  'crayfish',
  'creature',
  'cricket',
  'crocodile',
  'crocodileskink',
  'crossbill',
  'crow',
  'crownofthornsstarfish',
  'crustacean',
  'cub',
  'cuckoo',
  'cur',
  'curassow',
  'curlew',
  'cuscus',
  'cusimanse',
  'cuttlefish',
  'cutworm',
  'cygnet',
  'dachshund',
  'daddylonglegs',
  'dairycow',
  'dalmatian',
  'damselfly',
  'danishswedishfarmdog',
  'darklingbeetle',
  'dartfrog',
  'darwinsfox',
  'dassie',
  'dassierat',
  'davidstiger',
  'deer',
  'deermouse',
  'degu',
  'degus',
  'deinonychus',
  'desertpupfish',
  'devilfish',
  'deviltasmanian',
  'diamondbackrattlesnake',
  'dikdik',
  'dikkops',
  'dingo',
  'dinosaur',
  'diplodocus',
  'dipper',
  'discus',
  'dobermanpinscher',
  'doctorfish',
  'dodo',
  'dodobird',
  'doe',
  'dog',
  'dogfish',
  'dogwoodclubgall',
  'dogwoodtwigborer',
  'dolphin',
  'donkey',
  'dorado',
  'dore',
  'dorking',
  'dormouse',
  'dotterel',
  'douglasfirbarkbeetle',
  'dove',
  'dowitcher',
  'drafthorse',
  'dragon',
  'dragonfly',
  'drake',
  'drever',
  'dromaeosaur',
  'dromedary',
  'drongo',
  'duck',
  'duckbillcat',
  'duckbillplatypus',
  'duckling',
  'dugong',
  'duiker',
  'dungbeetle',
  'dungenesscrab',
  'dunlin',
  'dunnart',
  'dutchshepherddog',
  'dutchsmoushond',
  'dwarfmongoose',
  'dwarfrabbit',
  'eagle',
  'earthworm',
  'earwig',
  'easternglasslizard',
  'easternnewt',
  'easteuropeanshepherd',
  'eastrussiancoursinghounds',
  'eastsiberianlaika',
  'echidna',
  'eel',
  'eelelephant',
  'eeve',
  'eft',
  'egg',
  'egret',
  'eider',
  'eidolonhelvum',
  'ekaltadeta',
  'eland',
  'electriceel',
  'elephant',
  'elephantbeetle',
  'elephantseal',
  'elk',
  'elkhound',
  'elver',
  'emeraldtreeskink',
  'emperorpenguin',
  'emperorshrimp',
  'emu',
  'englishpointer',
  'englishsetter',
  'equestrian',
  'equine',
  'erin',
  'ermine',
  'erne',
  'eskimodog',
  'esok',
  'estuarinecrocodile',
  'ethiopianwolf',
  'europeanfiresalamander',
  'europeanpolecat',
  'ewe',
  'eyas',
  'eyelashpitviper',
  'eyra',
  'fairybluebird',
  'fairyfly',
  'falcon',
  'fallowdeer',
  'fantail',
  'fanworms',
  'fattaileddunnart',
  'fawn',
  'feline',
  'fennecfox',
  'ferret',
  'fiddlercrab',
  'fieldmouse',
  'fieldspaniel',
  'finch',
  'finnishspitz',
  'finwhale',
  'fireant',
  'firebelliedtoad',
  'firecrest',
  'firefly',
  'fish',
  'fishingcat',
  'flamingo',
  'flatcoatretriever',
  'flatfish',
  'flea',
  'flee',
  'flicker',
  'flickertailsquirrel',
  'flies',
  'flounder',
  'fluke',
  'fly',
  'flycatcher',
  'flyingfish',
  'flyingfox',
  'flyinglemur',
  'flyingsquirrel',
  'foal',
  'fossa',
  'fowl',
  'fox',
  'foxhound',
  'foxterrier',
  'frenchbulldog',
  'freshwatereel',
  'frigatebird',
  'frilledlizard',
  'frillneckedlizard',
  'fritillarybutterfly',
  'frog',
  'frogmouth',
  'fruitbat',
  'fruitfly',
  'fugu',
  'fulmar',
  'funnelweaverspider',
  'furseal',
  'gadwall',
  'galago',
  'galah',
  'galapagosalbatross',
  'galapagosdove',
  'galapagoshawk',
  'galapagosmockingbird',
  'galapagospenguin',
  'galapagossealion',
  'galapagostortoise',
  'gallinule',
  'gallowaycow',
  'gander',
  'gangesdolphin',
  'gannet',
  'gar',
  'gardensnake',
  'garpike',
  'gartersnake',
  'gaur',
  'gavial',
  'gazelle',
  'gecko',
  'geese',
  'gelada',
  'gelding',
  'gemsbok',
  'gemsbuck',
  'genet',
  'gentoopenguin',
  'gerbil',
  'gerenuk',
  'germanpinscher',
  'germanshepherd',
  'germanshorthairedpointer',
  'germanspaniel',
  'germanspitz',
  'germanwirehairedpointer',
  'gharial',
  'ghostshrimp',
  'giantschnauzer',
  'gibbon',
  'gilamonster',
  'giraffe',
  'glassfrog',
  'globefish',
  'glowworm',
  'gnat',
  'gnatcatcher',
  'gnu',
  'goa',
  'goat',
  'godwit',
  'goitered',
  'goldeneye',
  'goldenmantledgroundsquirrel',
  'goldenretriever',
  'goldfinch',
  'goldfish',
  'gonolek',
  'goose',
  'goosefish',
  'gopher',
  'goral',
  'gordonsetter',
  'gorilla',
  'goshawk',
  'gosling',
  'gossamerwingedbutterfly',
  'gourami',
  'grackle',
  'grasshopper',
  'grassspider',
  'grayfox',
  'grayling',
  'grayreefshark',
  'graysquirrel',
  'graywolf',
  'greatargus',
  'greatdane',
  'greathornedowl',
  'greatwhiteshark',
  'grebe',
  'greendarnerdragonfly',
  'greyhounddog',
  'grison',
  'grizzlybear',
  'grosbeak',
  'groundbeetle',
  'groundhog',
  'grouper',
  'grouse',
  'grub',
  'grunion',
  'guanaco',
  'guernseycow',
  'guillemot',
  'guineafowl',
  'guineapig',
  'gull',
  'guppy',
  'gypsymoth',
  'gyrfalcon',
  'hackee',
  'haddock',
  'hadrosaurus',
  'hagfish',
  'hairstreak',
  'hairstreakbutterfly',
  'hake',
  'halcyon',
  'halibut',
  'halicore',
  'hamadryad',
  'hamadryas',
  'hammerheadbird',
  'hammerheadshark',
  'hammerkop',
  'hamster',
  'hanumanmonkey',
  'hapuka',
  'hapuku',
  'harborporpoise',
  'harborseal',
  'hare',
  'harlequinbug',
  'harpseal',
  'harpyeagle',
  'harrier',
  'harrierhawk',
  'hart',
  'hartebeest',
  'harvestmen',
  'harvestmouse',
  'hatchetfish',
  'hawaiianmonkseal',
  'hawk',
  'hectorsdolphin',
  'hedgehog',
  'heifer',
  'hellbender',
  'hen',
  'herald',
  'herculesbeetle',
  'hermitcrab',
  'heron',
  'herring',
  'heterodontosaurus',
  'hind',
  'hippopotamus',
  'hoatzin',
  'hochstettersfrog',
  'hog',
  'hogget',
  'hoiho',
  'hoki',
  'homalocephale',
  'honeybadger',
  'honeybee',
  'honeycreeper',
  'honeyeater',
  'hookersealion',
  'hoopoe',
  'hornbill',
  'hornedtoad',
  'hornedviper',
  'hornet',
  'hornshark',
  'horse',
  'horsechestnutleafminer',
  'horsefly',
  'horsemouse',
  'horseshoebat',
  'horseshoecrab',
  'hound',
  'housefly',
  'hoverfly',
  'howlermonkey',
  'huemul',
  'huia',
  'human',
  'hummingbird',
  'humpbackwhale',
  'husky',
  'hydatidtapeworm',
  'hydra',
  'hyena',
  'hylaeosaurus',
  'hypacrosaurus',
  'hypsilophodon',
  'hyracotherium',
  'hyrax',
  'iaerismetalmark',
  'ibadanmalimbe',
  'iberianbarbel',
  'iberianchiffchaff',
  'iberianemeraldlizard',
  'iberianlynx',
  'iberianmidwifetoad',
  'iberianmole',
  'iberiannase',
  'ibex',
  'ibis',
  'ibisbill',
  'ibizanhound',
  'iceblueredtopzebra',
  'icefish',
  'icelandgull',
  'icelandichorse',
  'icelandicsheepdog',
  'ichidna',
  'ichneumonfly',
  'ichthyosaurs',
  'ichthyostega',
  'icterinewarbler',
  'iggypops',
  'iguana',
  'iguanodon',
  'illadopsis',
  'ilsamochadegu',
  'imago',
  'impala',
  'imperatorangel',
  'imperialeagle',
  'incatern',
  'inchworm',
  'indianabat',
  'indiancow',
  'indianelephant',
  'indianglassfish',
  'indianhare',
  'indianjackal',
  'indianpalmsquirrel',
  'indianpangolin',
  'indianrhinoceros',
  'indianringneckparakeet',
  'indianrockpython',
  'indianskimmer',
  'indianspinyloach',
  'indigobunting',
  'indigowingedparrot',
  'indochinahogdeer',
  'indochinesetiger',
  'indri',
  'indusriverdolphin',
  'inexpectatumpleco',
  'inganue',
  'insect',
  'intermediateegret',
  'invisiblerail',
  'iraniangroundjay',
  'iridescentshark',
  'iriomotecat',
  'irishdraughthorse',
  'irishredandwhitesetter',
  'irishsetter',
  'irishterrier',
  'irishwaterspaniel',
  'irishwolfhound',
  'irrawaddydolphin',
  'irukandjijellyfish',
  'isabellineshrike',
  'isabellinewheatear',
  'islandcanary',
  'islandwhistler',
  'isopod',
  'italianbrownbear',
  'italiangreyhound',
  'ivorybackedwoodswallow',
  'ivorybilledwoodpecker',
  'ivorygull',
  'izuthrush',
  'jabiru',
  'jackal',
  'jackrabbit',
  'jaeger',
  'jaguar',
  'jaguarundi',
  'janenschia',
  'japanesebeetle',
  'javalina',
  'jay',
  'jellyfish',
  'jenny',
  'jerboa',
  'joey',
  'johndory',
  'juliabutterfly',
  'jumpingbean',
  'junco',
  'junebug',
  'kagu',
  'kakapo',
  'kakarikis',
  'kangaroo',
  'karakul',
  'katydid',
  'kawala',
  'kentrosaurus',
  'kestrel',
  'kid',
  'killdeer',
  'killerwhale',
  'killifish',
  'kingbird',
  'kingfisher',
  'kinglet',
  'kingsnake',
  'kinkajou',
  'kiskadee',
  'kissingbug',
  'kite',
  'kitfox',
  'kitten',
  'kittiwake',
  'kitty',
  'kiwi',
  'koala',
  'koalabear',
  'kob',
  'kodiakbear',
  'koi',
  'komododragon',
  'koodoo',
  'kookaburra',
  'kouprey',
  'krill',
  'kronosaurus',
  'kudu',
  'kusimanse',
  'labradorretriever',
  'lacewing',
  'ladybird',
  'ladybug',
  'lamb',
  'lamprey',
  'langur',
  'lark',
  'larva',
  'laughingthrush',
  'lcont',
  'leafbird',
  'leafcutterant',
  'leafhopper',
  'leafwing',
  'leech',
  'lemming',
  'lemur',
  'leonberger',
  'leopard',
  'leopardseal',
  'leveret',
  'lhasaapso',
  'lice',
  'liger',
  'lightningbug',
  'limpet',
  'limpkin',
  'ling',
  'lion',
  'lionfish',
  'littlenightmonkeys',
  'lizard',
  'llama',
  'lobo',
  'lobster',
  'locust',
  'loggerheadturtle',
  'longhorn',
  'longhornbeetle',
  'longspur',
  'loon',
  'lorikeet',
  'loris',
  'louse',
  'lovebird',
  'lowchen',
  'lunamoth',
  'lungfish',
  'lynx',
  'lynxÂ',
  'macaque',
  'macaw',
  'macropod',
  'madagascarhissingroach',
  'maggot',
  'magpie',
  'maiasaura',
  'majungatholus',
  'malamute',
  'mallard',
  'maltesedog',
  'mamba',
  'mamenchisaurus',
  'mammal',
  'mammoth',
  'manatee',
  'mandrill',
  'mangabey',
  'manta',
  'mantaray',
  'mantid',
  'mantis',
  'mantisray',
  'manxcat',
  'mara',
  'marabou',
  'marbledmurrelet',
  'mare',
  'marlin',
  'marmoset',
  'marmot',
  'marten',
  'martin',
  'massasauga',
  'massospondylus',
  'mastiff',
  'mastodon',
  'mayfly',
  'meadowhawk',
  'meadowlark',
  'mealworm',
  'meerkat',
  'megalosaurus',
  'megalotomusquinquespinosus',
  'megaraptor',
  'merganser',
  'merlin',
  'metalmarkbutterfly',
  'metamorphosis',
  'mice',
  'microvenator',
  'midge',
  'milksnake',
  'milkweedbug',
  'millipede',
  'minibeast',
  'mink',
  'minnow',
  'mite',
  'moa',
  'mockingbird',
  'mole',
  'mollies',
  'mollusk',
  'molly',
  'monarch',
  'mongoose',
  'mongrel',
  'monkey',
  'monkfishÂ',
  'monoclonius',
  'montanoceratops',
  'moorhen',
  'moose',
  'moray',
  'morayeel',
  'morpho',
  'mosasaur',
  'mosquito',
  'moth',
  'motmot',
  'mouflon',
  'mountaincat',
  'mountainlion',
  'mouse',
  'mouse/mice',
  'mousebird',
  'mudpuppy',
  'mule',
  'mullet',
  'muntjac',
  'murrelet',
  'muskox',
  'muskrat',
  'mussaurus',
  'mussel',
  'mustang',
  'mutt',
  'myna',
  'mynah',
  'myotisÂ',
  'nabarlek',
  'nag',
  'naga',
  'nagapies',
  'nakedmolerat',
  'nandine',
  'nandoo',
  'nandu',
  'narwhal',
  'narwhale',
  'natterjacktoad',
  'nauplius',
  'nautilus',
  'needlefish',
  'needletail',
  'nematode',
  'nene',
  'neonblueguppy',
  'neonbluehermitcrab',
  'neondwarfgourami',
  'neonrainbowfish',
  'neonredguppy',
  'neontetra',
  'nerka',
  'nettlefish',
  'newfoundlanddog',
  'newt',
  'newtnutria',
  'nightcrawler',
  'nighthawk',
  'nightheron',
  'nightingale',
  'nightjar',
  'nijssenissdwarfchihlid',
  'nilgai',
  'ninebandedarmadillo',
  'noctilio',
  'noctule',
  'noddy',
  'noolbenger',
  'northerncardinals',
  'northernelephantseal',
  'northernflyingsquirrel',
  'northernfurseal',
  'northernhairynosedwombat',
  'northernpike',
  'northernseahorse',
  'northernspottedowl',
  'norwaylobster',
  'norwayrat',
  'nubiangoat',
  'nudibranch',
  'numbat',
  'nurseshark',
  'nutcracker',
  'nuthatch',
  'nutria',
  'nyala',
  'nymph',
  'ocelot',
  'octopus',
  'okapi',
  'olingo',
  'olm',
  'opossum',
  'orangutan',
  'orca',
  'oregonsilverspotbutterfly',
  'oriole',
  'oropendola',
  'oropendula',
  'oryx',
  'osprey',
  'ostracod',
  'ostrich',
  'otter',
  'ovenbird',
  'owl',
  'owlbutterfly',
  'ox',
  'oxen',
  'oxpecker',
  'oyster',
  'ozarkbigearedbat',
  'pacaÂ',
  'pachyderm',
  'pacificparrotlet',
  'paddlefish',
  'paintedladybutterfly',
  'panda',
  'pangolin',
  'panther',
  'paperwasp',
  'papillon',
  'parakeet',
  'parrot',
  'partridge',
  'peacock',
  'peafowl',
  'peccary',
  'pekingese',
  'pelican',
  'pelicinuspetrel',
  'penguin',
  'perch',
  'peregrinefalcon',
  'pewee',
  'phalarope',
  'pharaohhound',
  'pheasant',
  'phoebe',
  'phoenix',
  'pig',
  'pigeon',
  'piglet',
  'pika',
  'pike',
  'pikeperchÂ',
  'pilchard',
  'pinemarten',
  'pinkriverdolphin',
  'pinniped',
  'pintail',
  'pipistrelle',
  'pipit',
  'piranha',
  'pitbull',
  'pittabird',
  'plainsqueaker',
  'plankton',
  'planthopper',
  'platypus',
  'plover',
  'polarbear',
  'polecat',
  'polliwog',
  'polyp',
  'polyturator',
  'pomeranian',
  'pondskater',
  'pony',
  'pooch',
  'poodle',
  'porcupine',
  'porpoise',
  'portuguesemanofwar',
  'possum',
  'prairiedog',
  'prawn',
  'prayingmantid',
  'prayingmantis',
  'primate',
  'pronghorn',
  'pseudodynerusquadrisectus',
  'ptarmigan',
  'pterodactyls',
  'pterosaurs',
  'puffer',
  'pufferfish',
  'puffin',
  'pug',
  'pullet',
  'puma',
  'pupa',
  'pupfish',
  'puppy',
  'purplemarten',
  'pussycat',
  'pygmy',
  'python',
  'quadrisectus',
  'quagga',
  'quahog',
  'quail',
  'queenalexandrasbirdwing',
  'queenalexandrasbirdwingbutterfly',
  'queenant',
  'queenbee',
  'queenconch',
  'queenslandgrouper',
  'queenslandheeler',
  'queensnake',
  'quelea',
  'quetzal',
  'quetzalcoatlus',
  'quillback',
  'quinquespinosus',
  'quokka',
  'quoll',
  'rabbit',
  'rabidsquirrel',
  'raccoon',
  'racer',
  'racerunner',
  'ragfish',
  'rail',
  'rainbowfish',
  'rainbowlorikeet',
  'rainbowtrout',
  'ram',
  'raptors',
  'rasbora',
  'rat',
  'ratfish',
  'rattail',
  'rattlesnake',
  'raven',
  'ray',
  'redhead',
  'redheadedwoodpecker',
  'redpoll',
  'redstart',
  'redtailedhawk',
  'reindeer',
  'reptile',
  'reynard',
  'rhea',
  'rhesusmonkey',
  'rhino',
  'rhinoceros',
  'rhinocerosbeetle',
  'rhodesianridgeback',
  'ringtailedlemur',
  'ringworm',
  'riograndeescuerzo',
  'roach',
  'roadrunner',
  'roan',
  'robberfly',
  'robin',
  'rockrat',
  'rodent',
  'roebuck',
  'roller',
  'rook',
  'rooster',
  'rottweiler',
  'sable',
  'sableantelope',
  'sablefishÂ',
  'saiga',
  'sakimonkey',
  'salamander',
  'salmon',
  'saltwatercrocodile',
  'sambar',
  'samoyeddog',
  'sandbarshark',
  'sanddollar',
  'sanderling',
  'sandpiper',
  'sapsucker',
  'sardine',
  'sawfish',
  'scallop',
  'scarab',
  'scarletibis',
  'scaup',
  'schapendoes',
  'schipperke',
  'schnauzer',
  'scorpion',
  'scoter',
  'screamer',
  'seabird',
  'seagull',
  'seahog',
  'seahorse',
  'seal',
  'sealion',
  'seamonkey',
  'seaslug',
  'seaurchin',
  'senegalpython',
  'seriema',
  'serpent',
  'serval',
  'shark',
  'shearwater',
  'sheep',
  'sheldrake',
  'shelduck',
  'shibainu',
  'shihtzu',
  'shorebird',
  'shoveler',
  'shrew',
  'shrike',
  'shrimp',
  'siamang',
  'siamesecat',
  'siberiantiger',
  'sidewinder',
  'sifaka',
  'silkworm',
  'silverfish',
  'silverfox',
  'silversidefish',
  'siskin',
  'skimmer',
  'skink',
  'skipper',
  'skua',
  'skunk',
  'skylark',
  'sloth',
  'slothbear',
  'slug',
  'smelts',
  'smew',
  'snail',
  'snake',
  'snipe',
  'snoutbutterfly',
  'snowdog',
  'snowgeese',
  'snowleopard',
  'snowmonkey',
  'snowyowl',
  'sockeyesalmon',
  'solenodon',
  'solitaire',
  'songbird',
  'sora',
  'southernhairnosedwombat',
  'sow',
  'spadefoot',
  'sparrow',
  'sphinx',
  'spider',
  'spidermonkey',
  'spiketail',
  'spittlebug',
  'sponge',
  'spoonbill',
  'spotteddolphin',
  'spreadwing',
  'springbok',
  'springpeeper',
  'springtail',
  'squab',
  'squamata',
  'squeaker',
  'squid',
  'squirrel',
  'stag',
  'stagbeetle',
  'stallion',
  'starfish',
  'starling',
  'steed',
  'steer',
  'stegosaurus',
  'stickinsect',
  'stickleback',
  'stilt',
  'stingray',
  'stinkbug',
  'stinkpot',
  'stoat',
  'stonefly',
  'stork',
  'stud',
  'sturgeon',
  'sugarglider',
  'sulphurbutterfly',
  'sunbear',
  'sunbittern',
  'sunfish',
  'swallow',
  'swallowtail',
  'swallowtailbutterfly',
  'swan',
  'swellfish',
  'swift',
  'swordfish',
  'tadpole',
  'tahr',
  'takin',
  'tamarin',
  'tanager',
  'tapaculo',
  'tapeworm',
  'tapir',
  'tarantula',
  'tarpan',
  'tarsier',
  'taruca',
  'tasmaniandevil',
  'tasmaniantiger',
  'tattler',
  'tayra',
  'teal',
  'tegus',
  'teledu',
  'tench',
  'tenrec',
  'termite',
  'tern',
  'terrapin',
  'terrier',
  'thoroughbred',
  'thrasher',
  'thrip',
  'thrush',
  'thunderbird',
  'thylacine',
  'tick',
  'tiger',
  'tigerbeetle',
  'tigermoth',
  'tigershark',
  'tilefish',
  'tinamou',
  'titi',
  'titmouse',
  'toad',
  'toadfish',
  'tomtitÂ',
  'topi',
  'tortoise',
  'toucan',
  'towhee',
  'tragopan',
  'treecreeper',
  'trex',
  'triceratops',
  'trogon',
  'trout',
  'trumpeterbird',
  'trumpeterswan',
  'tsetsefly',
  'tuatara',
  'tuna',
  'turaco',
  'turkey',
  'turnstone',
  'turtle',
  'turtledove',
  'uakari',
  'ugandakob',
  'uintagroundsquirrel',
  'ulyssesbutterfly',
  'umbrellabird',
  'umbrette',
  'unau',
  'ungulate',
  'unicorn',
  'upupa',
  'urchin',
  'urial',
  'uromastyxmaliensis',
  'uromastyxspinipes',
  'urson',
  'urubu',
  'urus',
  'urutu',
  'urva',
  'utahprairiedog',
  'vampirebat',
  'vaquita',
  'veery',
  'velociraptor',
  'velvetcrab',
  'velvetworm',
  'venomoussnake',
  'verdin',
  'vervet',
  'viceroybutterfly',
  'vicuna',
  'viper',
  'viperfish',
  'vipersquid',
  'vireo',
  'virginiaopossum',
  'vixen',
  'vole',
  'volvox',
  'vulpesvelox',
  'vulpesvulpes',
  'vulture',
  'walkingstick',
  'wallaby',
  'wallaroo',
  'walleye',
  'walrus',
  'warbler',
  'warthog',
  'wasp',
  'waterboatman',
  'waterbuck',
  'waterbuffalo',
  'waterbug',
  'waterdogs',
  'waterdragons',
  'watermoccasin',
  'waterstrider',
  'waterthrush',
  'wattlebird',
  'watussi',
  'waxwing',
  'weasel',
  'weaverbird',
  'weevil',
  'westafricanantelope',
  'whale',
  'whapuku',
  'whelp',
  'whimbrel',
  'whippet',
  'whippoorwill',
  'whitebeakeddolphin',
  'whiteeye',
  'whitepelican',
  'whiterhino',
  'whitetaileddeer',
  'whitetippedreefshark',
  'whooper',
  'whoopingcrane',
  'widgeon',
  'widowspider',
  'wildcat',
  'wildebeast',
  'wildebeest',
  'willet',
  'wireworm',
  'wisent',
  'wobbegongshark',
  'wolf',
  'wolfspider',
  'wolverine',
  'wombat',
  'woodborer',
  'woodchuck',
  'woodcock',
  'woodnymphbutterfly',
  'woodpecker',
  'woodstorks',
  'woollybearcaterpillar',
  'worm',
  'wrasse',
  'wreckfish',
  'wren',
  'wrenchbird',
  'wryneck',
  'wuerhosaurus',
  'wyvern',
  'xanclomys',
  'xanthareel',
  'xantus',
  'xantusmurrelet',
  'xeme',
  'xenarthra',
  'xenoposeidon',
  'xenops',
  'xenopterygii',
  'xenopus',
  'xenotarsosaurus',
  'xenurine',
  'xenurusunicinctus',
  'xerus',
  'xiaosaurus',
  'xinjiangovenator',
  'xiphias',
  'xiphiasgladius',
  'xiphosuran',
  'xoloitzcuintli',
  'xoni',
  'xrayfish',
  'xraytetra',
  'xuanhanosaurus',
  'xuanhuaceratops',
  'xuanhuasaurus',
  'yaffle',
  'yak',
  'yapok',
  'yardant',
  'yearling',
  'yellowbelliedmarmot',
  'yellowbellylizard',
  'yellowhammer',
  'yellowjacket',
  'yellowlegs',
  'yellowthroat',
  'yellowwhitebutterfly',
  'yeti',
  'ynambu',
  'yorkshireterrier',
  'yosemitetoad',
  'yucker',
  'zander',
  'zanzibardaygecko',
  'zebra',
  'zebradove',
  'zebrafinch',
  'zebrafish',
  'zebralongwingbutterfly',
  'zebraswallowtailbutterfly',
  'zebratailedlizard',
  'zebu',
  'zenaida',
  'zeren',
  'zethusspinipes',
  'zethuswasp',
  'zigzagsalamander',
  'zonetailedpigeon',
  'zooplankton',
  'zopilote',
  'zorilla',
]

export const adjectives = [
  'abandoned',
  'able',
  'absolute',
  'academic',
  'acceptable',
  'acclaimed',
  'accomplished',
  'accurate',
  'aching',
  'acidic',
  'acrobatic',
  'adorable',
  'adventurous',
  'babyish',
  'back',
  'bad',
  'baggy',
  'bare',
  'barren',
  'basic',
  'beautiful',
  'belated',
  'beloved',
  'calculating',
  'calm',
  'candid',
  'canine',
  'capital',
  'carefree',
  'careful',
  'careless',
  'caring',
  'cautious',
  'cavernous',
  'celebrated',
  'charming',
  'damaged',
  'damp',
  'dangerous',
  'dapper',
  'daring',
  'dark',
  'darling',
  'dazzling',
  'dead',
  'deadly',
  'deafening',
  'dear',
  'dearest',
  'each',
  'eager',
  'early',
  'earnest',
  'easy',
  'easygoing',
  'ecstatic',
  'edible',
  'educated',
  'fabulous',
  'failing',
  'faint',
  'fair',
  'faithful',
  'fake',
  'familiar',
  'famous',
  'fancy',
  'fantastic',
  'far',
  'faraway',
  'farflung',
  'faroff',
  'gargantuan',
  'gaseous',
  'general',
  'generous',
  'gentle',
  'genuine',
  'giant',
  'giddy',
  'gigantic',
  'hairy',
  'half',
  'handmade',
  'handsome',
  'handy',
  'happy',
  'happygolucky',
  'hard',
  'icky',
  'icy',
  'ideal',
  'idealistic',
  'identical',
  'idiotic',
  'idle',
  'idolized',
  'ignorant',
  'ill',
  'illegal',
  'jaded',
  'jagged',
  'jampacked',
  'kaleidoscopic',
  'keen',
  'lame',
  'lanky',
  'large',
  'last',
  'lasting',
  'late',
  'lavish',
  'lawful',
  'mad',
  'madeup',
  'magnificent',
  'majestic',
  'major',
  'male',
  'mammoth',
  'married',
  'marvelous',
  'naive',
  'narrow',
  'nasty',
  'natural',
  'naughty',
  'obedient',
  'obese',
  'oblong',
  'oblong',
  'obvious',
  'occasional',
  'oily',
  'palatable',
  'pale',
  'paltry',
  'parallel',
  'parched',
  'partial',
  'passionate',
  'past',
  'pastel',
  'peaceful',
  'peppery',
  'perfect',
  'perfumed',
  'quaint',
  'qualified',
  'radiant',
  'ragged',
  'rapid',
  'rare',
  'rash',
  'raw',
  'recent',
  'reckless',
  'rectangular',
  'sad',
  'safe',
  'salty',
  'same',
  'sandy',
  'sane',
  'sarcastic',
  'sardonic',
  'satisfied',
  'scaly',
  'scarce',
  'scared',
  'scary',
  'scented',
  'scholarly',
  'scientific',
  'scornful',
  'scratchy',
  'scrawny',
  'second',
  'secondary',
  'secondhand',
  'secret',
  'selfassured',
  'selfish',
  'selfreliant',
  'sentimental',
  'talkative',
  'tall',
  'tame',
  'tan',
  'tangible',
  'tart',
  'tasty',
  'tattered',
  'taut',
  'tedious',
  'teeming',
  'ugly',
  'ultimate',
  'unacceptable',
  'unaware',
  'uncomfortable',
  'uncommon',
  'unconscious',
  'understated',
  'unequaled',
  'vacant',
  'vague',
  'vain',
  'valid',
  'wan',
  'warlike',
  'warm',
  'warmhearted',
  'warped',
  'wary',
  'wasteful',
  'watchful',
  'waterlogged',
  'watery',
  'wavy',
  'yawning',
  'yearly',
  'zany',
  'false',
  'active',
  'actual',
  'adept',
  'admirable',
  'admired',
  'adolescent',
  'adorable',
  'adored',
  'advanced',
  'affectionate',
  'afraid',
  'aged',
  'aggravating',
  'beneficial',
  'best',
  'better',
  'bewitched',
  'big',
  'bighearted',
  'biodegradable',
  'bitesized',
  'bitter',
  'black',
  'cheap',
  'cheerful',
  'cheery',
  'chief',
  'chilly',
  'chubby',
  'circular',
  'classic',
  'clean',
  'clear',
  'clearcut',
  'clever',
  'close',
  'closed',
  'decent',
  'decimal',
  'decisive',
  'deep',
  'defenseless',
  'defensive',
  'defiant',
  'deficient',
  'definite',
  'definitive',
  'delayed',
  'delectable',
  'delicious',
  'elaborate',
  'elastic',
  'elated',
  'elderly',
  'electric',
  'elegant',
  'elementary',
  'elliptical',
  'embarrassed',
  'fast',
  'fat',
  'fatal',
  'fatherly',
  'favorable',
  'favorite',
  'fearful',
  'fearless',
  'feisty',
  'feline',
  'female',
  'feminine',
  'few',
  'fickle',
  'gifted',
  'giving',
  'glamorous',
  'glaring',
  'glass',
  'gleaming',
  'gleeful',
  'glistening',
  'glittering',
  'hardtofind',
  'harmful',
  'harmless',
  'harmonious',
  'harsh',
  'hasty',
  'hateful',
  'haunting',
  'illfated',
  'illinformed',
  'illiterate',
  'illustrious',
  'imaginary',
  'imaginative',
  'immaculate',
  'immaterial',
  'immediate',
  'immense',
  'impassioned',
  'jaunty',
  'jealous',
  'jittery',
  'key',
  'kind',
  'lazy',
  'leading',
  'leafy',
  'lean',
  'left',
  'legal',
  'legitimate',
  'light',
  'masculine',
  'massive',
  'mature',
  'meager',
  'mealy',
  'mean',
  'measly',
  'meaty',
  'medical',
  'mediocre',
  'nautical',
  'near',
  'neat',
  'necessary',
  'needy',
  'odd',
  'oddball',
  'offbeat',
  'offensive',
  'official',
  'old',
  'periodic',
  'perky',
  'personal',
  'pertinent',
  'pesky',
  'pessimistic',
  'petty',
  'phony',
  'physical',
  'piercing',
  'pink',
  'pitiful',
  'plain',
  'quarrelsome',
  'quarterly',
  'ready',
  'real',
  'realistic',
  'reasonable',
  'red',
  'reflecting',
  'regal',
  'regular',
  'separate',
  'serene',
  'serious',
  'serpentine',
  'several',
  'severe',
  'shabby',
  'shadowy',
  'shady',
  'shallow',
  'shameful',
  'shameless',
  'sharp',
  'shimmering',
  'shiny',
  'shocked',
  'shocking',
  'shoddy',
  'short',
  'shortterm',
  'showy',
  'shrill',
  'shy',
  'sick',
  'silent',
  'silky',
  'tempting',
  'tender',
  'tense',
  'tepid',
  'terrible',
  'terrific',
  'testy',
  'thankful',
  'that',
  'these',
  'uneven',
  'unfinished',
  'unfit',
  'unfolded',
  'unfortunate',
  'unhappy',
  'unhealthy',
  'uniform',
  'unimportant',
  'unique',
  'valuable',
  'vapid',
  'variable',
  'vast',
  'velvety',
  'weak',
  'wealthy',
  'weary',
  'webbed',
  'wee',
  'weekly',
  'weepy',
  'weighty',
  'weird',
  'welcome',
  'welldocumented',
  'yellow',
  'zealous',
  'aggressive',
  'agile',
  'agitated',
  'agonizing',
  'agreeable',
  'ajar',
  'alarmed',
  'alarming',
  'alert',
  'alienated',
  'alive',
  'all',
  'altruistic',
  'blackandwhite',
  'bland',
  'blank',
  'blaring',
  'bleak',
  'blind',
  'blissful',
  'blond',
  'blue',
  'blushing',
  'cloudy',
  'clueless',
  'clumsy',
  'cluttered',
  'coarse',
  'cold',
  'colorful',
  'colorless',
  'colossal',
  'comfortable',
  'common',
  'compassionate',
  'competent',
  'complete',
  'delightful',
  'delirious',
  'demanding',
  'dense',
  'dental',
  'dependable',
  'dependent',
  'descriptive',
  'deserted',
  'detailed',
  'determined',
  'devoted',
  'different',
  'embellished',
  'eminent',
  'emotional',
  'empty',
  'enchanted',
  'enchanting',
  'energetic',
  'enlightened',
  'enormous',
  'filthy',
  'fine',
  'finished',
  'firm',
  'first',
  'firsthand',
  'fitting',
  'fixed',
  'flaky',
  'flamboyant',
  'flashy',
  'flat',
  'flawed',
  'flawless',
  'flickering',
  'gloomy',
  'glorious',
  'glossy',
  'glum',
  'golden',
  'good',
  'goodnatured',
  'gorgeous',
  'graceful',
  'healthy',
  'heartfelt',
  'hearty',
  'heavenly',
  'heavy',
  'hefty',
  'helpful',
  'helpless',
  'impartial',
  'impeccable',
  'imperfect',
  'imperturbable',
  'impish',
  'impolite',
  'important',
  'impossible',
  'impractical',
  'impressionable',
  'impressive',
  'improbable',
  'joint',
  'jolly',
  'jovial',
  'kindhearted',
  'kindly',
  'lighthearted',
  'likable',
  'likely',
  'limited',
  'limp',
  'limping',
  'linear',
  'lined',
  'liquid',
  'medium',
  'meek',
  'mellow',
  'melodic',
  'memorable',
  'menacing',
  'merry',
  'messy',
  'metallic',
  'mild',
  'negative',
  'neglected',
  'negligible',
  'neighboring',
  'nervous',
  'new',
  'oldfashioned',
  'only',
  'open',
  'optimal',
  'optimistic',
  'opulent',
  'plaintive',
  'plastic',
  'playful',
  'pleasant',
  'pleased',
  'pleasing',
  'plump',
  'plush',
  'pointed',
  'pointless',
  'poised',
  'polished',
  'polite',
  'political',
  'queasy',
  'querulous',
  'reliable',
  'relieved',
  'remarkable',
  'remorseful',
  'remote',
  'repentant',
  'required',
  'respectful',
  'responsible',
  'silly',
  'silver',
  'similar',
  'simple',
  'simplistic',
  'sinful',
  'single',
  'sizzling',
  'skeletal',
  'skinny',
  'sleepy',
  'slight',
  'slim',
  'slimy',
  'slippery',
  'slow',
  'slushy',
  'small',
  'smart',
  'smoggy',
  'smooth',
  'smug',
  'snappy',
  'snarling',
  'sneaky',
  'sniveling',
  'snoopy',
  'thick',
  'thin',
  'third',
  'thirsty',
  'this',
  'thorny',
  'thorough',
  'those',
  'thoughtful',
  'threadbare',
  'united',
  'unkempt',
  'unknown',
  'unlawful',
  'unlined',
  'unlucky',
  'unnatural',
  'unpleasant',
  'unrealistic',
  'venerated',
  'vengeful',
  'verifiable',
  'vibrant',
  'vicious',
  'wellgroomed',
  'wellinformed',
  'welllit',
  'wellmade',
  'welloff',
  'welltodo',
  'wellworn',
  'wet',
  'which',
  'whimsical',
  'whirlwind',
  'whispered',
  'yellowish',
  'zesty',
  'amazing',
  'ambitious',
  'ample',
  'amused',
  'amusing',
  'anchored',
  'ancient',
  'angelic',
  'angry',
  'anguished',
  'animated',
  'annual',
  'another',
  'antique',
  'bogus',
  'boiling',
  'bold',
  'bony',
  'boring',
  'bossy',
  'both',
  'bouncy',
  'bountiful',
  'bowed',
  'complex',
  'complicated',
  'composed',
  'concerned',
  'concrete',
  'confused',
  'conscious',
  'considerate',
  'constant',
  'content',
  'conventional',
  'cooked',
  'cool',
  'cooperative',
  'difficult',
  'digital',
  'diligent',
  'dim',
  'dimpled',
  'dimwitted',
  'direct',
  'disastrous',
  'discrete',
  'disfigured',
  'disgusting',
  'disloyal',
  'dismal',
  'enraged',
  'entire',
  'envious',
  'equal',
  'equatorial',
  'essential',
  'esteemed',
  'ethical',
  'euphoric',
  'flimsy',
  'flippant',
  'flowery',
  'fluffy',
  'fluid',
  'flustered',
  'focused',
  'fond',
  'foolhardy',
  'foolish',
  'forceful',
  'forked',
  'formal',
  'forsaken',
  'gracious',
  'grand',
  'grandiose',
  'granular',
  'grateful',
  'grave',
  'gray',
  'great',
  'greedy',
  'green',
  'hidden',
  'hideous',
  'high',
  'highlevel',
  'hilarious',
  'hoarse',
  'hollow',
  'homely',
  'impure',
  'inborn',
  'incomparable',
  'incompatible',
  'incomplete',
  'inconsequential',
  'incredible',
  'indelible',
  'indolent',
  'inexperienced',
  'infamous',
  'infantile',
  'joyful',
  'joyous',
  'jubilant',
  'klutzy',
  'knobby',
  'little',
  'live',
  'lively',
  'livid',
  'loathsome',
  'lone',
  'lonely',
  'long',
  'milky',
  'mindless',
  'miniature',
  'minor',
  'minty',
  'miserable',
  'miserly',
  'misguided',
  'misty',
  'mixed',
  'next',
  'nice',
  'nifty',
  'nimble',
  'nippy',
  'orange',
  'orderly',
  'ordinary',
  'organic',
  'ornate',
  'ornery',
  'poor',
  'popular',
  'portly',
  'posh',
  'positive',
  'possible',
  'potable',
  'powerful',
  'powerless',
  'practical',
  'precious',
  'present',
  'prestigious',
  'questionable',
  'quick',
  'repulsive',
  'revolving',
  'rewarding',
  'rich',
  'right',
  'rigid',
  'ringed',
  'ripe',
  'sociable',
  'soft',
  'soggy',
  'solid',
  'somber',
  'some',
  'sophisticated',
  'sore',
  'sorrowful',
  'soulful',
  'soupy',
  'sour',
  'spanish',
  'sparkling',
  'sparse',
  'specific',
  'spectacular',
  'speedy',
  'spherical',
  'spicy',
  'spiffy',
  'spirited',
  'spiteful',
  'splendid',
  'spotless',
  'spotted',
  'spry',
  'thrifty',
  'thunderous',
  'tidy',
  'tight',
  'timely',
  'tinted',
  'tiny',
  'tired',
  'torn',
  'total',
  'unripe',
  'unruly',
  'unselfish',
  'unsightly',
  'unsteady',
  'unsung',
  'untidy',
  'untimely',
  'untried',
  'victorious',
  'vigilant',
  'vigorous',
  'villainous',
  'violet',
  'white',
  'whole',
  'whopping',
  'wicked',
  'wide',
  'wideeyed',
  'wiggly',
  'wild',
  'willing',
  'wilted',
  'winding',
  'windy',
  'young',
  'zigzag',
  'anxious',
  'any',
  'apprehensive',
  'appropriate',
  'apt',
  'arctic',
  'arid',
  'aromatic',
  'artistic',
  'ashamed',
  'assured',
  'astonishing',
  'athletic',
  'brave',
  'breakable',
  'brief',
  'bright',
  'brilliant',
  'brisk',
  'broken',
  'bronze',
  'brown',
  'bruised',
  'coordinated',
  'corny',
  'corrupt',
  'costly',
  'courageous',
  'courteous',
  'crafty',
  'crazy',
  'creamy',
  'creative',
  'creepy',
  'criminal',
  'crisp',
  'dirty',
  'disguised',
  'dishonest',
  'dismal',
  'distant',
  'distant',
  'distinct',
  'distorted',
  'dizzy',
  'dopey',
  'downright',
  'dreary',
  'even',
  'evergreen',
  'everlasting',
  'every',
  'evil',
  'exalted',
  'excellent',
  'excitable',
  'exemplary',
  'exhausted',
  'forthright',
  'fortunate',
  'fragrant',
  'frail',
  'frank',
  'frayed',
  'free',
  'french',
  'frequent',
  'fresh',
  'friendly',
  'frightened',
  'frightening',
  'frigid',
  'gregarious',
  'grim',
  'grimy',
  'gripping',
  'grizzled',
  'gross',
  'grotesque',
  'grouchy',
  'grounded',
  'honest',
  'honorable',
  'honored',
  'hopeful',
  'horrible',
  'hospitable',
  'hot',
  'huge',
  'infatuated',
  'inferior',
  'infinite',
  'informal',
  'innocent',
  'insecure',
  'insidious',
  'insignificant',
  'insistent',
  'instructive',
  'insubstantial',
  'judicious',
  'juicy',
  'jumbo',
  'knotty',
  'knowing',
  'knowledgeable',
  'longterm',
  'loose',
  'lopsided',
  'lost',
  'loud',
  'lovable',
  'lovely',
  'loving',
  'modern',
  'modest',
  'moist',
  'monstrous',
  'monthly',
  'monumental',
  'moral',
  'mortified',
  'motherly',
  'motionless',
  'nocturnal',
  'noisy',
  'nonstop',
  'normal',
  'notable',
  'noted',
  'original',
  'other',
  'our',
  'outgoing',
  'outlandish',
  'outlying',
  'precious',
  'pretty',
  'previous',
  'pricey',
  'prickly',
  'primary',
  'prime',
  'pristine',
  'private',
  'prize',
  'probable',
  'productive',
  'profitable',
  'quickwitted',
  'quiet',
  'quintessential',
  'roasted',
  'robust',
  'rosy',
  'rotating',
  'rotten',
  'rough',
  'round',
  'rowdy',
  'square',
  'squeaky',
  'squiggly',
  'stable',
  'staid',
  'stained',
  'stale',
  'standard',
  'starchy',
  'stark',
  'starry',
  'steel',
  'steep',
  'sticky',
  'stiff',
  'stimulating',
  'stingy',
  'stormy',
  'straight',
  'strange',
  'strict',
  'strident',
  'striking',
  'striped',
  'strong',
  'studious',
  'stunning',
  'tough',
  'tragic',
  'trained',
  'traumatic',
  'treasured',
  'tremendous',
  'tremendous',
  'triangular',
  'tricky',
  'trifling',
  'trim',
  'untrue',
  'unused',
  'unusual',
  'unwelcome',
  'unwieldy',
  'unwilling',
  'unwitting',
  'unwritten',
  'upbeat',
  'violent',
  'virtual',
  'virtuous',
  'visible',
  'winged',
  'wiry',
  'wise',
  'witty',
  'wobbly',
  'woeful',
  'wonderful',
  'wooden',
  'woozy',
  'wordy',
  'worldly',
  'worn',
  'youthful',
  'attached',
  'attentive',
  'attractive',
  'austere',
  'authentic',
  'authorized',
  'automatic',
  'avaricious',
  'average',
  'aware',
  'awesome',
  'awful',
  'awkward',
  'bubbly',
  'bulky',
  'bumpy',
  'buoyant',
  'burdensome',
  'burly',
  'bustling',
  'busy',
  'buttery',
  'buzzing',
  'critical',
  'crooked',
  'crowded',
  'cruel',
  'crushing',
  'cuddly',
  'cultivated',
  'cultured',
  'cumbersome',
  'curly',
  'curvy',
  'cute',
  'cylindrical',
  'doting',
  'double',
  'downright',
  'drab',
  'drafty',
  'dramatic',
  'dreary',
  'droopy',
  'dry',
  'dual',
  'dull',
  'dutiful',
  'excited',
  'exciting',
  'exotic',
  'expensive',
  'experienced',
  'expert',
  'extralarge',
  'extraneous',
  'extrasmall',
  'extroverted',
  'frilly',
  'frivolous',
  'frizzy',
  'front',
  'frosty',
  'frozen',
  'frugal',
  'fruitful',
  'full',
  'fumbling',
  'functional',
  'funny',
  'fussy',
  'fuzzy',
  'growing',
  'growling',
  'grown',
  'grubby',
  'gruesome',
  'grumpy',
  'guilty',
  'gullible',
  'gummy',
  'humble',
  'humiliating',
  'humming',
  'humongous',
  'hungry',
  'hurtful',
  'husky',
  'intelligent',
  'intent',
  'intentional',
  'interesting',
  'internal',
  'international',
  'intrepid',
  'ironclad',
  'irresponsible',
  'irritating',
  'itchy',
  'jumpy',
  'junior',
  'juvenile',
  'known',
  'kooky',
  'kosher',
  'low',
  'loyal',
  'lucky',
  'lumbering',
  'luminous',
  'lumpy',
  'lustrous',
  'luxurious',
  'mountainous',
  'muddy',
  'muffled',
  'multicolored',
  'mundane',
  'murky',
  'mushy',
  'musty',
  'muted',
  'mysterious',
  'noteworthy',
  'novel',
  'noxious',
  'numb',
  'nutritious',
  'nutty',
  'onerlooked',
  'outrageous',
  'outstanding',
  'oval',
  'overcooked',
  'overdue',
  'overjoyed',
  'profuse',
  'proper',
  'proud',
  'prudent',
  'punctual',
  'pungent',
  'puny',
  'pure',
  'purple',
  'pushy',
  'putrid',
  'puzzled',
  'puzzling',
  'quirky',
  'quixotic',
  'quizzical',
  'royal',
  'rubbery',
  'ruddy',
  'rude',
  'rundown',
  'runny',
  'rural',
  'rusty',
  'stupendous',
  'stupid',
  'sturdy',
  'stylish',
  'subdued',
  'submissive',
  'substantial',
  'subtle',
  'suburban',
  'sudden',
  'sugary',
  'sunny',
  'super',
  'superb',
  'superficial',
  'superior',
  'supportive',
  'surefooted',
  'surprised',
  'suspicious',
  'svelte',
  'sweaty',
  'sweet',
  'sweltering',
  'swift',
  'sympathetic',
  'trivial',
  'troubled',
  'trusting',
  'trustworthy',
  'trusty',
  'truthful',
  'tubby',
  'turbulent',
  'twin',
  'upright',
  'upset',
  'urban',
  'usable',
  'used',
  'useful',
  'useless',
  'utilized',
  'utter',
  'vital',
  'vivacious',
  'vivid',
  'voluminous',
  'worried',
  'worrisome',
  'worse',
  'worst',
  'worthless',
  'worthwhile',
  'worthy',
  'wrathful',
  'wretched',
  'writhing',
  'wrong',
  'wry',
  'yummy',
  'true',
  'aliceblue',
  'antiquewhite',
  'aqua',
  'aquamarine',
  'azure',
  'beige',
  'bisque',
  'black',
  'blanchedalmond',
  'blue',
  'blueviolet',
  'brown',
  'burlywood',
  'cadetblue',
  'chartreuse',
  'chocolate',
  'coral',
  'cornflowerblue',
  'cornsilk',
  'crimson',
  'cyan',
  'darkblue',
  'darkcyan',
  'darkgoldenrod',
  'darkgray',
  'darkgreen',
  'darkgrey',
  'darkkhaki',
  'darkmagenta',
  'darkolivegreen',
  'darkorange',
  'darkorchid',
  'darkred',
  'darksalmon',
  'darkseagreen',
  'darkslateblue',
  'darkslategray',
  'darkslategrey',
  'darkturquoise',
  'darkviolet',
  'deeppink',
  'deepskyblue',
  'dimgray',
  'dimgrey',
  'dodgerblue',
  'firebrick',
  'floralwhite',
  'forestgreen',
  'fractal',
  'fuchsia',
  'gainsboro',
  'ghostwhite',
  'gold',
  'goldenrod',
  'gray',
  'green',
  'greenyellow',
  'honeydew',
  'hotpink',
  'indianred',
  'indigo',
  'ivory',
  'khaki',
  'lavender',
  'lavenderblush',
  'lawngreen',
  'lemonchiffon',
  'lightblue',
  'lightcoral',
  'lightcyan',
  'lightgoldenrod',
  'lightgoldenrodyellow',
  'lightgray',
  'lightgreen',
  'lightgrey',
  'lightpink',
  'lightsalmon',
  'lightseagreen',
  'lightskyblue',
  'lightslateblue',
  'lightslategray',
  'lightsteelblue',
  'lightyellow',
  'lime',
  'limegreen',
  'linen',
  'magenta',
  'maroon',
  'mediumaquamarine',
  'mediumblue',
  'mediumforestgreen',
  'mediumgoldenrod',
  'mediumorchid',
  'mediumpurple',
  'mediumseagreen',
  'mediumslateblue',
  'mediumspringgreen',
  'mediumturquoise',
  'mediumvioletred',
  'midnightblue',
  'mintcream',
  'mistyrose',
  'moccasin',
  'navajowhite',
  'navy',
  'navyblue',
  'oldlace',
  'olive',
  'olivedrab',
  'opaque',
  'orange',
  'orangered',
  'orchid',
  'palegoldenrod',
  'palegreen',
  'paleturquoise',
  'palevioletred',
  'papayawhip',
  'peachpuff',
  'peru',
  'pink',
  'plum',
  'powderblue',
  'purple',
  'red',
  'rosybrown',
  'royalblue',
  'saddlebrown',
  'salmon',
  'sandybrown',
  'seagreen',
  'seashell',
  'sienna',
  'silver',
  'skyblue',
  'slateblue',
  'slategray',
  'slategrey',
  'snow',
  'springgreen',
  'steelblue',
  'tan',
  'teal',
  'thistle',
  'tomato',
  'transparent',
  'turquoise',
  'violet',
  'violetred',
  'wheat',
  'white',
  'whitesmoke',
  'yellow',
  'yellowgreen',
]
